var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Vue from 'vue';
import Component from 'vue-class-component';
import Navbar from '../components/Navbar.vue';
import SpecialityCard from '../components/SpecialityCard.vue';
import CarclewFooter from '../components/CarclewFooter.vue';
import { Prop } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { ActionTypes } from '../store/actions';
import FadeImg from '../components/FadeImg.vue';
var MentorProfile = /** @class */ (function (_super) {
    __extends(MentorProfile, _super);
    function MentorProfile() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.expandedSection = 0;
        _this.mentorInfo = null;
        return _this;
    }
    Object.defineProperty(MentorProfile.prototype, "isSaved", {
        get: function () {
            var _this = this;
            if (this.currentUser.type != 'student')
                return false;
            var user = this.currentUser;
            return !!user.mentors.filter(function (mentor) { return mentor.id.toString() === _this.id; }).length;
        },
        enumerable: false,
        configurable: true
    });
    MentorProfile.prototype.created = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('mentor', this.mentor);
                        if (!this.mentor) return [3 /*break*/, 1];
                        this.mentorInfo = this.mentor;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.getMentorInfo()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MentorProfile.prototype.getMentorInfo = function () {
        return __awaiter(this, void 0, void 0, function () {
            var res, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.get("/mentor/" + this.id)];
                    case 1:
                        res = _a.sent();
                        console.log(res.data);
                        this.mentorInfo = res.data;
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        console.error(err_1);
                        if (err_1.response)
                            console.error(err_1.response);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MentorProfile.prototype.saveOrRemoveMentor = function () {
        if (!this.mentorInfo)
            return;
        if (!this.isSaved)
            this.saveMentor(this.mentorInfo);
        else
            this.removeMentor(this.mentorInfo.id);
    };
    MentorProfile.prototype.toggleAccordion = function (section) {
        this.expandedSection = this.expandedSection !== section ? section : -1;
    };
    MentorProfile.prototype.backToSearch = function () {
        this.$router.go(-1);
    };
    __decorate([
        Prop(String)
    ], MentorProfile.prototype, "id", void 0);
    __decorate([
        Prop(Object)
    ], MentorProfile.prototype, "mentor", void 0);
    __decorate([
        State
    ], MentorProfile.prototype, "currentUser", void 0);
    __decorate([
        Action(ActionTypes.SAVE_MENTOR)
    ], MentorProfile.prototype, "saveMentor", void 0);
    __decorate([
        Action(ActionTypes.REMOVE_MENTOR)
    ], MentorProfile.prototype, "removeMentor", void 0);
    MentorProfile = __decorate([
        Component({
            components: {
                FadeImg: FadeImg,
                CarclewFooter: CarclewFooter,
                Navbar: Navbar,
                SpecialityCard: SpecialityCard
            },
            metaInfo: function () {
                return {
                    title: 'Carclew - Music at Work | Mentor Profile'
                };
            }
        })
    ], MentorProfile);
    return MentorProfile;
}(Vue));
export default MentorProfile;
