var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import CarclewFooter from '../components/CarclewFooter.vue';
import Navbar from '../components/Navbar.vue';
var Resources = /** @class */ (function (_super) {
    __extends(Resources, _super);
    function Resources() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.expandedSection = -1;
        return _this;
    }
    Resources.prototype.created = function () {
        this.page = pages.resources.data;
    };
    Resources.prototype.toggleAccordion = function (section) {
        var expandedAccordionItems = this.$refs.expandedAccordionItem;
        if (expandedAccordionItems) {
            for (var _i = 0, expandedAccordionItems_1 = expandedAccordionItems; _i < expandedAccordionItems_1.length; _i++) {
                var item = expandedAccordionItems_1[_i];
                item.style.height = item.scrollHeight + 'px';
            }
        }
        this.expandedSection = this.expandedSection !== section ? section : -1;
    };
    Resources.prototype.enter = function (el) {
        el.style.height = el.scrollHeight + 'px';
    };
    Resources.prototype.afterEnter = function (el) {
        el.style.height = 'auto';
    };
    Resources.prototype.leave = function (el) {
        el.style.height = '0';
    };
    Resources = __decorate([
        Component({
            components: {
                Navbar: Navbar,
                CarclewFooter: CarclewFooter
            },
            metaInfo: function () {
                return {
                    title: 'Carclew - Music at Work | Resources'
                };
            }
        })
    ], Resources);
    return Resources;
}(Vue));
export default Resources;
