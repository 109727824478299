import Vue from 'vue';
import axios from 'axios';
import router from './router';
import store from './store';
import VueVisible from 'vue-visible';
import VueYoutube from 'vue-youtube';
import VueMeta from 'vue-meta';
import App from './App.vue';
import SvgVue from 'svg-vue';
import VueGtag from 'vue-gtag';
window.axios = axios;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';
Vue.component('App', App);
Vue.use(VueVisible);
Vue.use(VueYoutube);
Vue.use(VueMeta);
Vue.use(SvgVue);
Vue.use(VueGtag, {
    config: { id: 'UA-25208038-6' }
}, router);
// if (process.env.NODE_ENV === 'production')
Vue.prototype.$storageBaseURL = 'https://storage.googleapis.com/carclew-musicatwork.appspot.com';
// else
// 	Vue.prototype.$storageBaseURL = window.location.protocol + "//" + window.location.host
new Vue({
    router: router,
    render: function (h) { return h(App); },
    store: store
}).$mount('#app');
