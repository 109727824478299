var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Component from 'vue-class-component';
import { Mutation, State } from 'vuex-class';
import CarclewFooter from '../components/CarclewFooter.vue';
import Navbar from '../components/Navbar.vue';
import { MutationTypes } from '../store/mutations';
import MentorSearchResult from '../components/MentorSearchResult.vue';
import Multiselect from 'vue-multiselect';
import vue2Dropzone from 'vue2-dropzone';
import Vue from 'vue';
import SpecialityFormItem from '../components/SpecialityFormItem.vue';
var UpdateProfileMentor = /** @class */ (function (_super) {
    __extends(UpdateProfileMentor, _super);
    function UpdateProfileMentor() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.errorMessage = '';
        _this.isSubmitting = false;
        _this.dropzoneOptions = {
            url: '#',
            autoProcessQueue: false,
            maxFilesize: 0.5,
            maxFiles: 1,
            // previewTemplate: '<div />',
            acceptedFiles: 'image/*'
        };
        _this.galleryDropzoneOptions = {
            url: '#',
            autoProcessQueue: false,
            maxFilesize: 0.5,
            acceptedFiles: 'image/*',
            maxFiles: 5,
            addRemoveLinks: true,
            dictRemoveFile: ''
        };
        _this.profilePhotoFile = null;
        _this.profilePhotoFilePreview = null;
        _this.galleryPhotoFiles = [];
        return _this;
    }
    UpdateProfileMentor.prototype.created = function () {
        this.profilePhoto = this.currentUser.profile_photo;
        this.introduction = this.currentUser.introduction;
        this.biography = this.currentUser.biography;
        this.website = this.currentUser.website;
        this.youtube = this.currentUser.youtube;
        this.facebook = this.currentUser.facebook;
        this.instagram = this.currentUser.instagram;
        this.galleryPhotoIDs = this.currentUser.gallery_photos.map(function (photo) { return photo.id; });
    };
    UpdateProfileMentor.prototype.onGalleryDropzoneReady = function () {
        for (var _i = 0, _a = this.currentUser.gallery_photos; _i < _a.length; _i++) {
            var media = _a[_i];
            var mockFile = { name: media.id, size: 0 };
            console.log(Vue.prototype.$storageBaseURL + "/" + media.id + "/" + media.file_name);
            this.$refs.galleryDropzone.manuallyAddFile(mockFile, Vue.prototype.$storageBaseURL + "/" + media.id + "/" + media.file_name);
        }
    };
    UpdateProfileMentor.prototype.onGalleryPhotoAdded = function (file) {
        console.log('file', file);
        if (file.size > 0) {
            if (this.galleryPhotoFiles.length < 5)
                this.galleryPhotoFiles.push(file);
            else
                this.$refs.galleryDropzone.removeFile(file);
        }
        console.log(this.galleryPhotoFiles);
    };
    UpdateProfileMentor.prototype.onGalleryPhotoRemoved = function (file) {
        console.log('file', file);
        this.galleryPhotoIDs.splice(this.galleryPhotoIDs.indexOf(file.name), 1);
    };
    UpdateProfileMentor.prototype.onChooseProfilePhoto = function (e) {
        var _this = this;
        var files = e.target.files;
        if (files && files.length) {
            var reader = new FileReader();
            reader.onload = function (e) {
                var _a;
                _this.profilePhotoFilePreview = ((_a = e.target) === null || _a === void 0 ? void 0 : _a.result) ? e.target.result : null;
            };
            reader.readAsDataURL(files[0]);
            this.profilePhoto = null;
            this.profilePhotoFile = files[0];
        }
    };
    UpdateProfileMentor.prototype.updateProfile = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var data, formData, key, item, _i, _b, id, _c, _d, photo, res, err_1, errorKey, _e, _f, error;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        this.errorMessage = '';
                        this.isSubmitting = true;
                        _g.label = 1;
                    case 1:
                        _g.trys.push([1, 4, , 5]);
                        data = {
                            introduction: this.introduction,
                            biography: this.biography,
                            website: this.website,
                            youtube: this.youtube,
                            facebook: this.facebook,
                            instagram: this.instagram,
                            profile_photo_id: (_a = this.profilePhoto) === null || _a === void 0 ? void 0 : _a.id,
                            profile_photo_upload: this.profilePhotoFile || undefined,
                            gallery_photo_ids: this.galleryPhotoIDs
                        };
                        formData = new FormData();
                        for (key in data) {
                            item = data[key];
                            if (item === null)
                                item = '';
                            if (item !== undefined)
                                formData.append(key, item);
                        }
                        if (this.galleryPhotoIDs.length) {
                            for (_i = 0, _b = this.galleryPhotoIDs; _i < _b.length; _i++) {
                                id = _b[_i];
                                formData.append('gallery_photo_ids[]', id.toString());
                            }
                        }
                        else {
                            formData.append('gallery_photo_ids', '');
                        }
                        for (_c = 0, _d = this.galleryPhotoFiles; _c < _d.length; _c++) {
                            photo = _d[_c];
                            formData.append('gallery_photo_uploads[]', photo);
                        }
                        console.log('formData', data);
                        return [4 /*yield*/, axios.post('/mentor/update', formData)];
                    case 2:
                        res = _g.sent();
                        console.log(res.data);
                        this.setCurrentUser(res.data);
                        return [4 /*yield*/, this.$router.push({ name: this.currentUser.specialities.length ? 'mentorDashboard' : 'updateSpeciality' })];
                    case 3:
                        _g.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _g.sent();
                        console.error(err_1);
                        if (err_1.response) {
                            console.error(err_1.response);
                            if (err_1.response.data.errors) {
                                for (errorKey in err_1.response.data.errors) {
                                    for (_e = 0, _f = err_1.response.data.errors[errorKey]; _e < _f.length; _e++) {
                                        error = _f[_e];
                                        this.errorMessage += error + '\n';
                                    }
                                }
                            }
                            else {
                                this.errorMessage = err_1.response.data;
                            }
                        }
                        return [3 /*break*/, 5];
                    case 5:
                        this.isSubmitting = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        State
    ], UpdateProfileMentor.prototype, "currentUser", void 0);
    __decorate([
        Mutation(MutationTypes.SET_CURRENT_USER)
    ], UpdateProfileMentor.prototype, "setCurrentUser", void 0);
    UpdateProfileMentor = __decorate([
        Component({
            components: {
                MentorSearchResult: MentorSearchResult,
                Navbar: Navbar,
                CarclewFooter: CarclewFooter,
                Multiselect: Multiselect,
                'vue-dropzone': vue2Dropzone,
                SpecialityFormItem: SpecialityFormItem
            },
            metaInfo: function () {
                return {
                    title: 'Carclew - Music at Work | Update Profile'
                };
            }
        })
    ], UpdateProfileMentor);
    return UpdateProfileMentor;
}(Vue));
export default UpdateProfileMentor;
