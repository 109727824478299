var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import Navbar from '../components/Navbar.vue';
import CarclewFooter from '../components/CarclewFooter.vue';
import { Prop } from 'vue-property-decorator';
import FadeImg from '../components/FadeImg.vue';
var SpecialityCard = /** @class */ (function (_super) {
    __extends(SpecialityCard, _super);
    function SpecialityCard() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(SpecialityCard.prototype, "skillLevelString", {
        get: function () {
            var skillLevels = this.speciality.pivot.skill_levels;
            var skillLevelString = '';
            if (skillLevels.beginner) {
                skillLevelString += 'Beginner';
                if (skillLevels.advanced)
                    skillLevelString += ' - Advanced';
                else if (skillLevels.intermediate)
                    skillLevelString += ' - Intermediate';
            }
            else if (skillLevels.intermediate) {
                skillLevelString += 'Intermediate';
                if (skillLevels.advanced)
                    skillLevelString += ' - Advanced';
            }
            else if (skillLevels.advanced) {
                skillLevelString += 'Advanced';
            }
            return skillLevelString;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop(Object)
    ], SpecialityCard.prototype, "speciality", void 0);
    __decorate([
        Prop({ type: Boolean, default: false })
    ], SpecialityCard.prototype, "editable", void 0);
    SpecialityCard = __decorate([
        Component({
            components: {
                FadeImg: FadeImg,
                CarclewFooter: CarclewFooter,
                Navbar: Navbar
            }
        })
    ], SpecialityCard);
    return SpecialityCard;
}(Vue));
export default SpecialityCard;
