var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Vue from 'vue';
import Component from 'vue-class-component';
import CarclewFooter from '../components/CarclewFooter.vue';
import Multiselect from 'vue-multiselect';
import MentorSearchResult from '../components/MentorSearchResult.vue';
import Navbar from '../components/Navbar.vue';
import FadeImg from '../components/FadeImg.vue';
var Search = /** @class */ (function (_super) {
    __extends(Search, _super);
    function Search() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isSearching = false;
        _this.genre = null;
        _this.selectedSpeciality = null;
        _this.skillLevel = null;
        _this.availableSkillLevels = ['Beginner', 'Intermediate', 'Advanced'];
        _this.searchAll = false;
        _this.gender = null;
        _this.availableGenders = ['Male', 'Female', 'Other'];
        _this.age = null;
        _this.availableAges = ['18-25', '26-35', '36+'];
        _this.mentorResults = null;
        return _this;
    }
    Search.prototype.created = function () {
        return __awaiter(this, void 0, void 0, function () {
            var speciality;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.availableGenres = genres;
                        this.availableSpecialities = specialities;
                        this.genre = this.$route.query.genre;
                        speciality = this.$route.query.speciality;
                        this.selectedSpeciality = this.availableSpecialities.find(function (s) { return s.name === speciality; }) || null;
                        this.skillLevel = this.$route.query.skill;
                        this.searchAll = !!this.$route.query.all;
                        console.log(this.genre, this.selectedSpeciality, this.skillLevel, this.searchAll);
                        if (!(this.genre || this.selectedSpeciality || this.skillLevel || this.searchAll)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.search()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    Search.prototype.newSearch = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!this.genre && !this.selectedSpeciality && !this.skillLevel)
                            this.searchAll = true;
                        else if (this.genre === this.$route.query.genre && ((_a = this.selectedSpeciality) === null || _a === void 0 ? void 0 : _a.name) === this.$route.query.speciality && this.skillLevel === this.$route.query.skill)
                            return [2 /*return*/];
                        return [4 /*yield*/, this.$router.replace({
                                name: 'search', query: {
                                    genre: this.genre || undefined,
                                    speciality: ((_b = this.selectedSpeciality) === null || _b === void 0 ? void 0 : _b.name) || undefined,
                                    skill: this.skillLevel || undefined,
                                    all: (this.searchAll && 'true') || undefined
                                }
                            })];
                    case 1:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Search.prototype.search = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var res, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isSearching = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, axios.post('/search', {
                                speciality: ((_a = this.selectedSpeciality) === null || _a === void 0 ? void 0 : _a.name) || undefined,
                                skillLevel: this.skillLevel || undefined
                            })];
                    case 2:
                        res = _b.sent();
                        console.log(res.data);
                        this.mentorResults = res.data;
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _b.sent();
                        console.error(err_1);
                        if (err_1.response)
                            console.error(err_1.response);
                        return [3 /*break*/, 4];
                    case 4:
                        this.isSearching = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    Search = __decorate([
        Component({
            components: {
                Multiselect: Multiselect,
                Navbar: Navbar,
                MentorSearchResult: MentorSearchResult,
                CarclewFooter: CarclewFooter,
                FadeImg: FadeImg
            },
            metaInfo: function () {
                return {
                    title: 'Carclew - Music at Work | Search'
                };
            }
        })
    ], Search);
    return Search;
}(Vue));
export default Search;
