import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import actions from './actions';
import state from './state';
import mutations from './mutations';
Vue.use(Vuex);
var options = {
    state: state,
    mutations: mutations,
    actions: actions,
    strict: true,
};
var store = new Store(options);
export default store;
