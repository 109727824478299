var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from './views/Home.vue';
import About from './views/About.vue';
import Signup from './views/Signup.vue';
import Login from './views/Login.vue';
import Contact from './views/Contact.vue';
import FAQs from './views/FAQs.vue';
import DashboardStudent from './views/DashboardStudent.vue';
import DashboardMentor from './views/DashboardMentor.vue';
import Search from './views/Search.vue';
import MentorProfile from './views/MentorProfile.vue';
import ForgotPassword from './views/ForgotPassword.vue';
import ResetPassword from './views/ResetPassword.vue';
import store from './store';
import { MutationTypes } from './store/mutations';
import PendingActivation from './views/PendingActivation.vue';
import UpdateProfileMentor from './views/UpdateProfileMentor.vue';
import UpdateSpeciality from './views/UpdateSpeciality.vue';
import UpdateAccountMentor from './views/UpdateAccountMentor.vue';
import UpdateDocumentsMentor from './views/UpdateDocumentsMentor.vue';
import Logout from './views/Logout.vue';
import Resources from './views/Resources.vue';
Vue.use(VueRouter);
var router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'index',
            component: Home
        },
        {
            path: '/home',
            name: 'home',
            component: Home
        },
        {
            path: '/about',
            name: 'about',
            component: About
        },
        {
            path: '/contact',
            name: 'contact',
            component: Contact
        },
        {
            path: '/faqs',
            name: 'faqs',
            component: FAQs
        },
        {
            path: '/resources',
            name: 'resources',
            component: Resources
        },
        {
            path: '/signup',
            name: 'signup',
            component: Signup
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/forgot-password',
            name: 'forgotPassword',
            component: ForgotPassword
        },
        {
            path: '/activation/pending',
            name: 'pendingActivation',
            component: PendingActivation
        },
        {
            path: '/password/reset/:token',
            name: 'resetPassword',
            component: ResetPassword,
            props: function (route) { return ({
                token: route.params.token,
                email: route.query.email,
                type: route.query.type
            }); }
        },
        {
            path: '/student/dashboard',
            name: 'studentDashboard',
            component: DashboardStudent,
            meta: {
                auth: true
            }
        },
        {
            path: '/mentor/dashboard',
            name: 'mentorDashboard',
            component: DashboardMentor,
            meta: {
                auth: true
            }
        },
        {
            path: '/mentor/profile/speciality',
            name: 'updateSpeciality',
            component: UpdateSpeciality,
            props: function (route) { return ({
                speciality: route.params.speciality
            }); },
            meta: {
                auth: true
            }
        },
        {
            path: '/student/search',
            name: 'search',
            component: Search,
            meta: {
                auth: true
            }
        },
        {
            path: '/profile/mentor/:id',
            name: 'mentorProfile',
            component: MentorProfile,
            props: function (route) { return ({
                id: route.params.id,
                mentor: route.params.mentor
            }); },
            meta: {
                auth: true
            }
        },
        {
            path: '/mentor/account',
            name: 'updateAccountMentor',
            component: UpdateAccountMentor,
            meta: {
                auth: true
            }
        },
        {
            path: '/mentor/profile/update',
            name: 'updateProfileMentor',
            component: UpdateProfileMentor,
            meta: {
                auth: true
            }
        },
        {
            path: '/mentor/documents',
            name: 'updateDocumentsMentor',
            component: UpdateDocumentsMentor,
            meta: {
                auth: true
            }
        },
        {
            path: '/logout',
            name: 'logout',
            component: Logout
        },
        // {
        // 	path: '/styles',
        // 	name: 'styles',
        // 	component: Styles
        // }
        {
            path: '*',
            redirect: '/'
        }
    ],
    scrollBehavior: function (to, from, savedPosition) {
        if (savedPosition)
            return savedPosition;
        else
            return { x: 0, y: 0 };
    }
});
router.beforeEach(function (to, from, next) { return __awaiter(void 0, void 0, void 0, function () {
    var userJSON, res, user, err_1, user;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (!!store.state.currentUser) return [3 /*break*/, 4];
                userJSON = localStorage.getItem('user');
                if (!userJSON) return [3 /*break*/, 4];
                _c.label = 1;
            case 1:
                _c.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.get('/user')];
            case 2:
                res = _c.sent();
                console.log(res.data);
                user = res.data;
                store.commit(MutationTypes.SET_CURRENT_USER, user);
                return [3 /*break*/, 4];
            case 3:
                err_1 = _c.sent();
                console.error(err_1);
                if (err_1.response)
                    console.error(err_1.response);
                if (err_1.response.status === 401)
                    // Not authorized
                    store.commit(MutationTypes.SET_CURRENT_USER, undefined);
                else
                    // Other error, set user from saved info
                    store.commit(MutationTypes.SET_CURRENT_USER, JSON.parse(userJSON));
                return [3 /*break*/, 4];
            case 4:
                if (to.meta.auth) {
                    console.log(store.state.currentUser);
                    if (!store.state.currentUser) {
                        console.error('Not logged in');
                        return [2 /*return*/, next('/')];
                    }
                    else if (!store.state.currentUser.email_verified_at) {
                        console.error('Not activated');
                        return [2 /*return*/, next({ name: 'pendingActivation' })];
                    }
                    else if (store.state.currentUser.type === 'mentor' && store.state.currentUser.profile_status === 'incomplete') {
                        console.error('Profile incomplete');
                        console.log(to.name);
                        user = store.state.currentUser;
                        if (to.name != 'updateAccountMentor' && !user.phone)
                            return [2 /*return*/, next({ name: 'updateAccountMentor' })];
                    }
                }
                if ((to.name === 'signup' || to.name === 'login') && store.state.currentUser) {
                    if (!store.state.currentUser.email_verified_at) {
                        console.error('Not activated');
                        return [2 /*return*/, next({ name: 'pendingActivation' })];
                    }
                    else {
                        console.error('Already logged in');
                        if (store.state.currentUser.type === 'student')
                            return [2 /*return*/, next({ name: 'studentDashboard' })];
                        else
                            return [2 /*return*/, next({ name: 'mentorDashboard' })];
                    }
                }
                else if (to.name === 'pendingActivation' && store.state.currentUser && store.state.currentUser.email_verified_at) {
                    console.error('Email activated');
                    if (store.state.currentUser.type === 'student')
                        return [2 /*return*/, next({ name: 'studentDashboard' })];
                    else
                        return [2 /*return*/, next({ name: 'mentorDashboard' })];
                }
                else if (to.name === 'studentDashboard' && ((_a = store.state.currentUser) === null || _a === void 0 ? void 0 : _a.type) !== 'student'
                    || to.name === 'mentorDashboard' && ((_b = store.state.currentUser) === null || _b === void 0 ? void 0 : _b.type) !== 'mentor') {
                    console.error('Wrong user type');
                    return [2 /*return*/, next('/')];
                }
                return [2 /*return*/, next()];
        }
    });
}); });
export default router;
