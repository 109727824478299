var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Vue from 'vue';
import Component from 'vue-class-component';
import CarclewFooter from "../components/CarclewFooter.vue";
import Navbar from '../components/Navbar.vue';
import Multiselect from 'vue-multiselect';
import { Mutation } from 'vuex-class';
import { MutationTypes } from '../store/mutations';
var Signup = /** @class */ (function (_super) {
    __extends(Signup, _super);
    function Signup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.userTypeOptions = [
            { name: 'Mentor', value: 'mentor' },
            { name: 'Teacher', value: 'student' }
        ];
        _this.selectedUserTypeOption = null;
        _this.userType = null;
        _this.isSubmitting = false;
        _this.signupSuccess = false;
        _this.schoolOptions = [];
        _this.selectedSchool = null;
        _this.name = '';
        _this.email = '';
        _this.password = '';
        _this.passwordConfirm = '';
        _this.errorMessage = null;
        return _this;
    }
    Signup.prototype.created = function () {
        var _this = this;
        this.page = pages.signup.data;
        this.schoolOptions = window.schools;
        this.userType = this.$route.params.userType || null;
        this.selectedUserTypeOption = this.userTypeOptions.find(function (option) { return option.value === _this.userType; }) || null;
    };
    Signup.prototype.onSelectUserType = function (option) {
        this.userType = option.value;
    };
    Signup.prototype.register = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var data, res, userRes, user, err_1, errors;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.userType === 'student') {
                            if (this.selectedSchool && !this.selectedSchool.email_domains.concat(['schools.edu.au', 'madeinkatana.com', 'carclew.org.au', 'musicatwork.org.au']).includes(this.email.split('@')[1])) {
                                this.errorMessage = 'Please use your school email address';
                                return [2 /*return*/];
                            }
                        }
                        this.errorMessage = null;
                        this.isSubmitting = true;
                        data = {
                            type: this.userType,
                            name: this.name,
                            email: this.email,
                            password: this.password,
                            password_confirmation: this.passwordConfirm,
                        };
                        if (this.userType === 'student')
                            data.school_id = (_a = this.selectedSchool) === null || _a === void 0 ? void 0 : _a.id;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, axios.post('/register', data)];
                    case 2:
                        res = _b.sent();
                        console.log(res.data);
                        return [4 /*yield*/, axios.get('/user')];
                    case 3:
                        userRes = _b.sent();
                        console.log(userRes.data);
                        user = userRes.data;
                        this.setCurrentUser(user);
                        this.signupSuccess = true;
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _b.sent();
                        console.error(err_1);
                        if (err_1.response)
                            console.error(err_1.response);
                        errors = err_1.response.data.errors;
                        if (errors)
                            this.errorMessage = errors[Object.keys(errors)[0]][0];
                        return [3 /*break*/, 5];
                    case 5:
                        this.isSubmitting = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        Mutation(MutationTypes.SET_CURRENT_USER)
    ], Signup.prototype, "setCurrentUser", void 0);
    Signup = __decorate([
        Component({
            components: {
                Navbar: Navbar,
                CarclewFooter: CarclewFooter,
                Multiselect: Multiselect,
            }
        })
    ], Signup);
    return Signup;
}(Vue));
export default Signup;
