var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import Multiselect from 'vue-multiselect';
import { PropSync } from 'vue-property-decorator';
var SpecialityFormItem = /** @class */ (function (_super) {
    __extends(SpecialityFormItem, _super);
    function SpecialityFormItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.selectedSpeciality = null;
        return _this;
    }
    Object.defineProperty(SpecialityFormItem.prototype, "isSkillLevelSelected", {
        get: function () {
            return this.syncedSpeciality.pivot.skill_levels.beginner ||
                this.syncedSpeciality.pivot.skill_levels.intermediate ||
                this.syncedSpeciality.pivot.skill_levels.advanced;
        },
        enumerable: false,
        configurable: true
    });
    SpecialityFormItem.prototype.created = function () {
        var _this = this;
        this.availableSpecialities = specialities;
        this.availableGenres = genres;
        console.log('speciality', this.syncedSpeciality);
        if (this.syncedSpeciality.id)
            this.selectedSpeciality = this.availableSpecialities.find(function (s) { return s.id === _this.syncedSpeciality.id; }) || null;
    };
    SpecialityFormItem.prototype.onSelectSpeciality = function (speciality) {
        this.syncedSpeciality.id = speciality.id;
    };
    __decorate([
        PropSync('speciality', { type: Object })
    ], SpecialityFormItem.prototype, "syncedSpeciality", void 0);
    SpecialityFormItem = __decorate([
        Component({
            components: {
                Multiselect: Multiselect
            }
        })
    ], SpecialityFormItem);
    return SpecialityFormItem;
}(Vue));
export default SpecialityFormItem;
