import { render, staticRenderFns } from "./PendingActivation.vue?vue&type=template&id=57d26290&scoped=true&lang=pug&"
import script from "./PendingActivation.vue?vue&type=script&lang=ts&"
export * from "./PendingActivation.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57d26290",
  null
  
)

export default component.exports