var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Component from 'vue-class-component';
import CarclewFooter from '../components/CarclewFooter.vue';
import Navbar from '../components/Navbar.vue';
import Multiselect from 'vue-multiselect';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { Mutation, State } from 'vuex-class';
import { MutationTypes } from '../store/mutations';
var UpdateSpeciality = /** @class */ (function (_super) {
    __extends(UpdateSpeciality, _super);
    function UpdateSpeciality() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.errorMessage = '';
        _this.isSubmitting = false;
        _this.isDeleting = false;
        _this.selectedSpeciality = null;
        _this.selectedSpecialityType = null;
        _this.skillLevelOptions = [
            { name: 'Beginner', value: 'beginner' },
            { name: 'Intermediate', value: 'intermediate' },
            { name: 'Advanced', value: 'advanced' },
        ];
        _this.skillLevels = {
            beginner: false,
            intermediate: false,
            advanced: false
        };
        _this.yearsExperience = null;
        _this.selectedGenres = [];
        return _this;
    }
    Object.defineProperty(UpdateSpeciality.prototype, "availableSpecialities", {
        get: function () {
            var _this = this;
            return specialities.filter(function (speciality) { return speciality.type === _this.selectedSpecialityType; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UpdateSpeciality.prototype, "isSkillLevelSelected", {
        get: function () {
            return this.skillLevels.beginner ||
                this.skillLevels.intermediate ||
                this.skillLevels.advanced;
        },
        enumerable: false,
        configurable: true
    });
    UpdateSpeciality.prototype.created = function () {
        var _this = this;
        var _a;
        this.availableSpecialityTypes = specialities
            .map(function (speciality) { return speciality.type; })
            .filter(function (value, index, self) { return self.indexOf(value) === index; });
        this.availableGenres = genres;
        if (this.speciality) {
            this.selectedSpeciality = specialities.find(function (s) { return s.id === _this.speciality.id; }) || null;
            this.selectedSpecialityType = ((_a = this.selectedSpeciality) === null || _a === void 0 ? void 0 : _a.type) || '';
            this.yearsExperience = this.speciality.pivot.years_experience;
            this.skillLevels = __assign({}, this.speciality.pivot.skill_levels);
            this.selectedGenres = this.speciality.pivot.genres;
        }
    };
    UpdateSpeciality.prototype.addCustomGenre = function (tag) {
        this.selectedGenres.push(tag);
    };
    UpdateSpeciality.prototype.getSpecialityTypeLabel = function (option) {
        return option[0].toUpperCase() + option.slice(1);
    };
    UpdateSpeciality.prototype.onSelectSpecialityType = function (option) {
        var _a;
        if (((_a = this.selectedSpeciality) === null || _a === void 0 ? void 0 : _a.type) !== option)
            this.selectedSpeciality = null;
    };
    UpdateSpeciality.prototype.saveSpeciality = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data, res, err_1, errorKey, _i, _a, error;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.selectedSpeciality)
                            return [2 /*return*/];
                        this.errorMessage = '';
                        this.isSubmitting = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 4, , 5]);
                        data = {
                            speciality_id: this.selectedSpeciality.id,
                            years_experience: this.yearsExperience,
                            skill_levels: JSON.stringify(this.skillLevels),
                            genres: this.selectedGenres
                        };
                        return [4 /*yield*/, axios.post('/mentor/speciality', data)];
                    case 2:
                        res = _b.sent();
                        console.log(res.data);
                        this.setCurrentUser(res.data);
                        return [4 /*yield*/, this.$router.replace({ name: 'mentorDashboard' })];
                    case 3:
                        _b.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _b.sent();
                        console.error(err_1);
                        if (err_1.response) {
                            console.error(err_1.response);
                            if (err_1.response.data.errors) {
                                for (errorKey in err_1.response.data.errors) {
                                    for (_i = 0, _a = err_1.response.data.errors[errorKey]; _i < _a.length; _i++) {
                                        error = _a[_i];
                                        this.errorMessage += error + '\n';
                                    }
                                }
                            }
                            else {
                                this.errorMessage = err_1.response.data;
                            }
                        }
                        return [3 /*break*/, 5];
                    case 5:
                        this.isSubmitting = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    UpdateSpeciality.prototype.removeSpeciality = function () {
        return __awaiter(this, void 0, void 0, function () {
            var conf, res, err_2, errorKey, _i, _a, error;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.selectedSpeciality)
                            return [2 /*return*/];
                        conf = confirm('Do you want to remove this speciality?');
                        if (!conf)
                            return [2 /*return*/];
                        this.errorMessage = '';
                        this.isDeleting = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, axios.delete('/mentor/speciality', {
                                params: {
                                    speciality_id: this.selectedSpeciality.id
                                }
                            })];
                    case 2:
                        res = _b.sent();
                        console.log(res.data);
                        this.setCurrentUser(res.data);
                        return [4 /*yield*/, this.$router.replace({ name: 'mentorDashboard' })];
                    case 3:
                        _b.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        err_2 = _b.sent();
                        console.error(err_2);
                        if (err_2.response) {
                            console.error(err_2.response);
                            if (err_2.response.data.errors) {
                                for (errorKey in err_2.response.data.errors) {
                                    for (_i = 0, _a = err_2.response.data.errors[errorKey]; _i < _a.length; _i++) {
                                        error = _a[_i];
                                        this.errorMessage += error + '\n';
                                    }
                                }
                            }
                            else {
                                this.errorMessage = err_2.response.data;
                            }
                        }
                        return [3 /*break*/, 5];
                    case 5:
                        this.isDeleting = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        Prop(Object)
    ], UpdateSpeciality.prototype, "speciality", void 0);
    __decorate([
        State
    ], UpdateSpeciality.prototype, "currentUser", void 0);
    __decorate([
        Mutation(MutationTypes.SET_CURRENT_USER)
    ], UpdateSpeciality.prototype, "setCurrentUser", void 0);
    UpdateSpeciality = __decorate([
        Component({
            components: {
                Navbar: Navbar,
                CarclewFooter: CarclewFooter,
                Multiselect: Multiselect
            },
            metaInfo: function () {
                return {
                    title: 'Carclew | Update Speciality'
                };
            }
        })
    ], UpdateSpeciality);
    return UpdateSpeciality;
}(Vue));
export default UpdateSpeciality;
