var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Component from 'vue-class-component';
import { Mutation, State } from 'vuex-class';
import CarclewFooter from '../components/CarclewFooter.vue';
import Navbar from '../components/Navbar.vue';
import { MutationTypes } from '../store/mutations';
import MentorSearchResult from '../components/MentorSearchResult.vue';
import Multiselect from 'vue-multiselect';
import { Loader } from '@googlemaps/js-api-loader';
import vue2Dropzone from 'vue2-dropzone';
import dayjs from 'dayjs';
import Vue from 'vue';
var UpdateAccountMentor = /** @class */ (function (_super) {
    __extends(UpdateAccountMentor, _super);
    function UpdateAccountMentor() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.placeSuggestions = [];
        _this.selectedPlaceSuggestion = null;
        _this.dobString = '';
        _this.distanceOptions = [
            { name: '5km', value: 5 },
            { name: '20km', value: 20 },
            { name: '50km', value: 50 },
            { name: '100km', value: 100 },
            { name: '100+km', value: 0 }
        ];
        _this.genderOptions = [
            { name: 'Male', value: 'male' },
            { name: 'Female', value: 'female' },
            { name: 'Non-binary', value: 'non_binary' },
            { name: 'Other', value: 'other' },
            { name: 'Rather not specify', value: 'not_specified' }
        ];
        _this.pronounsOptions = [
            { name: 'He/Him', value: 'he_him' },
            { name: 'She/Her', value: 'she_her' },
            { name: 'He/They', value: 'he_they' },
            { name: 'She/They', value: 'she_they' },
            { name: 'They/Them', value: 'they_them' },
        ];
        _this.driversLicenseOptions = [
            { name: 'Yes', value: true },
            { name: 'No', value: false }
        ];
        _this.diversityOptions = [
            { name: 'Aboriginal or Torres Strait Islander', value: 'aboriginal_tsi' },
            { name: 'Culturally diverse background', value: 'culturally_diverse' },
            { name: 'LGBTQIA+', value: 'lgbtqia' },
            { name: 'Physical functional capacity requirement(s)', value: 'physical_reqs' },
            { name: 'Sensory functional capacity requirement(s)', value: 'sensory_reqs' },
            { name: 'Mental functional capacity requirement(s)', value: 'mental_reqs' },
            { name: 'Learning difficulties', value: 'learning_difficulties' },
            { name: 'Carer', value: 'carer' },
            { name: 'Health Care or Concession card holder', value: 'health_concession_card' },
        ];
        _this.diversity = {
            aboriginal_tsi: false,
            culturally_diverse: false,
            lgbtqia: false,
            physical_reqs: false,
            sensory_reqs: false,
            mental_reqs: false,
            learning_difficulties: false,
            carer: false,
            health_concession_card: false,
        };
        _this.errorMessage = '';
        _this.isSubmitting = false;
        return _this;
    }
    UpdateAccountMentor.prototype.created = function () {
        this.phone = this.currentUser.phone;
        this.dobString = dayjs(this.currentUser.dob).format('YYYY-MM-DD');
        this.address = this.currentUser.address;
        this.latitude = this.currentUser.latitude;
        this.longitude = this.currentUser.longitude;
        this.gender = this.currentUser.gender;
        this.pronouns = this.currentUser.pronouns;
        this.distance = this.currentUser.distance;
        this.hourlyRateMin = this.currentUser.hourly_rate_min;
        this.hourlyRateMax = this.currentUser.hourly_rate_max;
        this.driversLicense = this.currentUser.drivers_license;
        this.diversity = __assign({}, this.currentUser.diversity);
        this.accessRequirements = this.currentUser.access_requirements;
    };
    UpdateAccountMentor.prototype.mounted = function () {
        return __awaiter(this, void 0, void 0, function () {
            var loader, google;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loader = new Loader({
                            apiKey: 'AIzaSyBf5YtaHjiyZfKsfq49RJRk30APqloTw9k',
                            libraries: ['places']
                        });
                        return [4 /*yield*/, loader.load()];
                    case 1:
                        google = _a.sent();
                        this.placesAutocompleteService = new google.maps.places.AutocompleteService();
                        this.$refs.addressInput.search = this.currentUser.address;
                        return [2 /*return*/];
                }
            });
        });
    };
    UpdateAccountMentor.prototype.getPlaceSuggestions = function (query) {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.placesAutocompleteService || !query)
                            return [2 /*return*/];
                        if (!(query.trim().length > 2)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.placesAutocompleteService.getPlacePredictions({
                                input: query
                            })];
                    case 1:
                        result = _a.sent();
                        this.placeSuggestions = result.predictions;
                        return [3 /*break*/, 3];
                    case 2:
                        this.placeSuggestions = [];
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UpdateAccountMentor.prototype.onSelectPlaceSuggestion = function (suggestion) {
        return __awaiter(this, void 0, void 0, function () {
            var geocoder, response, placeResult, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        geocoder = new google.maps.Geocoder();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, geocoder.geocode({ placeId: suggestion.place_id })];
                    case 2:
                        response = _a.sent();
                        placeResult = response.results[0];
                        this.address = placeResult.formatted_address;
                        this.latitude = placeResult.geometry.location.lat();
                        this.longitude = placeResult.geometry.location.lng();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    UpdateAccountMentor.prototype.updateProfile = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data, formData, key, item, res, err_1, errorKey, _i, _a, error;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.errorMessage = '';
                        this.isSubmitting = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 7, , 8]);
                        data = {
                            phone: this.phone,
                            dob: dayjs(this.dobString).format('YYYY-MM-DD'),
                            address: this.address,
                            latitude: this.latitude,
                            longitude: this.longitude,
                            gender: this.gender,
                            pronouns: this.pronouns,
                            distance: this.distance,
                            hourly_rate_min: this.hourlyRateMin,
                            hourly_rate_max: this.hourlyRateMax,
                            drivers_license: Number(this.driversLicense),
                            diversity: JSON.stringify(this.diversity),
                            access_requirements: this.accessRequirements,
                        };
                        formData = new FormData();
                        for (key in data) {
                            item = data[key];
                            if (item === null)
                                item = '';
                            if (item !== undefined)
                                formData.append(key, item);
                        }
                        console.log('formData', data);
                        return [4 /*yield*/, axios.post('/mentor/signup', formData)];
                    case 2:
                        res = _b.sent();
                        console.log(res.data);
                        this.setCurrentUser(res.data);
                        if (!(this.currentUser.profile_status === 'incomplete')) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.$router.push({ name: 'updateDocumentsMentor' })];
                    case 3:
                        _b.sent();
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.$router.push({ name: 'mentorDashboard' })];
                    case 5:
                        _b.sent();
                        _b.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        err_1 = _b.sent();
                        console.error(err_1);
                        if (err_1.response) {
                            console.error(err_1.response);
                            if (err_1.response.data.errors) {
                                for (errorKey in err_1.response.data.errors) {
                                    for (_i = 0, _a = err_1.response.data.errors[errorKey]; _i < _a.length; _i++) {
                                        error = _a[_i];
                                        this.errorMessage += error + '\n';
                                    }
                                }
                            }
                            else {
                                this.errorMessage = err_1.response.data;
                            }
                        }
                        return [3 /*break*/, 8];
                    case 8:
                        this.isSubmitting = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        State
    ], UpdateAccountMentor.prototype, "currentUser", void 0);
    __decorate([
        Mutation(MutationTypes.SET_CURRENT_USER)
    ], UpdateAccountMentor.prototype, "setCurrentUser", void 0);
    UpdateAccountMentor = __decorate([
        Component({
            components: {
                MentorSearchResult: MentorSearchResult,
                Navbar: Navbar,
                CarclewFooter: CarclewFooter,
                Multiselect: Multiselect,
                'vue-dropzone': vue2Dropzone
            },
            metaInfo: function () {
                return {
                    title: 'Carclew | Update Account'
                };
            }
        })
    ], UpdateAccountMentor);
    return UpdateAccountMentor;
}(Vue));
export default UpdateAccountMentor;
