var _a;
export var MutationTypes;
(function (MutationTypes) {
    MutationTypes["SET_CURRENT_USER"] = "MUTATION.SET_CURRENT_USER";
    MutationTypes["SAVE_MENTOR"] = "MUTATION.SAVE_MENTOR";
    MutationTypes["REMOVE_MENTOR"] = "MUTATION.REMOVE_MENTOR";
    MutationTypes["TOGGLE_MOBILE_MENU"] = "MUTATION.TOGGLE_MOBILE_MENU";
})(MutationTypes || (MutationTypes = {}));
var mutations = (_a = {},
    _a[MutationTypes.SET_CURRENT_USER] = function (state, payload) {
        state.currentUser = payload;
        if (payload)
            localStorage.setItem('user', JSON.stringify(payload));
        else
            localStorage.removeItem('user');
    },
    _a[MutationTypes.SAVE_MENTOR] = function (state, mentor) {
        var currentStudent = state.currentUser;
        if (currentStudent) {
            currentStudent.mentors.push(mentor);
            localStorage.setItem('user', JSON.stringify(currentStudent));
        }
    },
    _a[MutationTypes.REMOVE_MENTOR] = function (state, mentorID) {
        var currentStudent = state.currentUser;
        if (currentStudent) {
            var matchIndex = currentStudent.mentors.findIndex(function (mentor) { return mentor.id === mentorID; });
            if (matchIndex >= 0) {
                currentStudent.mentors.splice(matchIndex, 1);
                localStorage.setItem('user', JSON.stringify(currentStudent));
            }
        }
    },
    _a[MutationTypes.TOGGLE_MOBILE_MENU] = function (state) {
        state.menuOpen = !state.menuOpen;
    },
    _a);
export default mutations;
