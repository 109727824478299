var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import Component from 'vue-class-component';
import { Mutation, State } from 'vuex-class';
import CarclewFooter from '../components/CarclewFooter.vue';
import Navbar from '../components/Navbar.vue';
import { MutationTypes } from '../store/mutations';
import MentorSearchResult from '../components/MentorSearchResult.vue';
import Multiselect from 'vue-multiselect';
import vue2Dropzone from 'vue2-dropzone';
import Vue from 'vue';
var UpdateDocumentsMentor = /** @class */ (function (_super) {
    __extends(UpdateDocumentsMentor, _super);
    function UpdateDocumentsMentor() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.vaccinationStatusOptions = [
            { name: 'Fully Vaccinated', value: 'full' },
            { name: 'Partially Vaccinated', value: 'partial' },
            { name: 'Not Vaccinated', value: 'none' }
        ];
        _this.vaccinationCertificateFile = null;
        _this.wwcClearanceFile = null;
        _this.wwcCertNumber = null;
        _this.ranUploads = [{
                file: undefined
            }];
        _this.otherCertificateUploads = [{
                title: '',
                file: undefined
            }];
        _this.errorMessage = '';
        _this.isSubmitting = false;
        return _this;
    }
    UpdateDocumentsMentor.prototype.created = function () {
        var _a;
        this.vaccinationStatus = this.currentUser.vaccination_status;
        this.wwcClearanceFileID = (_a = this.currentUser.wwc_clearance) === null || _a === void 0 ? void 0 : _a.id;
        this.wwcCertNumber = this.currentUser.wwc_cert_number;
        this.existingRanCertificates = __spreadArray([], this.currentUser.ran_training);
        this.existingOtherCertificates = __spreadArray([], this.currentUser.other_certificates);
    };
    UpdateDocumentsMentor.prototype.onChooseVaccinationCertificateFile = function (e) {
        var files = e.target.files;
        if (files === null || files === void 0 ? void 0 : files.length) {
            console.log(files[0]);
            this.vaccinationCertificateFile = files[0];
        }
    };
    UpdateDocumentsMentor.prototype.onChooseWWCFile = function (e) {
        var files = e.target.files;
        if (files === null || files === void 0 ? void 0 : files.length) {
            console.log(files[0]);
            this.wwcClearanceFile = files[0];
        }
    };
    UpdateDocumentsMentor.prototype.onChooseRANFile = function (e, index) {
        var files = e.target.files;
        if (files === null || files === void 0 ? void 0 : files.length) {
            console.log(files[0]);
            this.ranUploads[index].file = files[0];
            this.ranUploads.push({});
            this.$forceUpdate();
        }
    };
    UpdateDocumentsMentor.prototype.removeRAN = function (index) {
        this.existingRanCertificates.splice(index, 1);
        this.$forceUpdate();
    };
    UpdateDocumentsMentor.prototype.onChooseOtherCertificateFile = function (e, index) {
        var files = e.target.files;
        if (files === null || files === void 0 ? void 0 : files.length) {
            console.log(files[0]);
            this.otherCertificateUploads[index].file = files[0];
            this.otherCertificateUploads.push({
                title: ''
            });
            this.$forceUpdate();
        }
    };
    UpdateDocumentsMentor.prototype.addEmptyCertificate = function () {
        this.otherCertificateUploads.push({ title: '', file: undefined });
    };
    UpdateDocumentsMentor.prototype.removeCertificate = function (index) {
        this.existingOtherCertificates.splice(index, 1);
        this.$forceUpdate();
    };
    UpdateDocumentsMentor.prototype.updateProfile = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data, formData, key, item, _i, _a, certificate, _b, _c, certificate, _d, _e, certificate, _f, _g, certificate, title, res, err_1, errorKey, _h, _j, error;
            return __generator(this, function (_k) {
                switch (_k.label) {
                    case 0:
                        this.errorMessage = '';
                        this.isSubmitting = true;
                        _k.label = 1;
                    case 1:
                        _k.trys.push([1, 4, , 5]);
                        data = {
                            vaccination_status: this.vaccinationStatus,
                            vaccination_certificate_upload: this.vaccinationCertificateFile || undefined,
                            wwc_clearance_file_id: this.wwcClearanceFileID,
                            wwc_clearance_upload: this.wwcClearanceFile || undefined,
                            wwc_cert_number: this.wwcCertNumber || undefined,
                        };
                        formData = new FormData();
                        for (key in data) {
                            item = data[key];
                            if (item === null)
                                item = '';
                            if (item !== undefined)
                                formData.append(key, item);
                        }
                        if (this.existingRanCertificates.length) {
                            for (_i = 0, _a = this.existingRanCertificates; _i < _a.length; _i++) {
                                certificate = _a[_i];
                                formData.append('ran_certificate_ids[]', certificate.id.toString());
                            }
                        }
                        else {
                            formData.append('ran_certificate_ids', '');
                        }
                        for (_b = 0, _c = this.ranUploads; _b < _c.length; _b++) {
                            certificate = _c[_b];
                            if (certificate.file) {
                                formData.append('ran_uploads[]', certificate.file);
                            }
                        }
                        if (this.existingOtherCertificates.length) {
                            for (_d = 0, _e = this.existingOtherCertificates; _d < _e.length; _d++) {
                                certificate = _e[_d];
                                formData.append('other_certificate_ids[]', certificate.id.toString());
                            }
                        }
                        else {
                            formData.append('other_certificate_ids', '');
                        }
                        for (_f = 0, _g = this.otherCertificateUploads; _f < _g.length; _f++) {
                            certificate = _g[_f];
                            if (certificate.title && certificate.file) {
                                title = certificate.title.toLowerCase().replace(/[\s]/g, '_').replace(/[\W]/g, '') + '.pdf';
                                formData.append('certificate_uploads[]', certificate.file, title);
                            }
                        }
                        console.log('formData', data);
                        return [4 /*yield*/, axios.post('/mentor/documents/update', formData)];
                    case 2:
                        res = _k.sent();
                        console.log(res.data);
                        this.setCurrentUser(res.data);
                        return [4 /*yield*/, this.$router.push({ name: this.currentUser.introduction ? (this.currentUser.specialities.length ? 'mentorDashboard' : 'updateSpeciality') : 'updateProfileMentor' })];
                    case 3:
                        _k.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _k.sent();
                        console.error(err_1);
                        if (err_1.response) {
                            console.error(err_1.response);
                            if (err_1.response.data.errors) {
                                for (errorKey in err_1.response.data.errors) {
                                    for (_h = 0, _j = err_1.response.data.errors[errorKey]; _h < _j.length; _h++) {
                                        error = _j[_h];
                                        this.errorMessage += error + '\n';
                                    }
                                }
                            }
                            else {
                                this.errorMessage = err_1.response.data;
                            }
                        }
                        return [3 /*break*/, 5];
                    case 5:
                        this.isSubmitting = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        State
    ], UpdateDocumentsMentor.prototype, "currentUser", void 0);
    __decorate([
        Mutation(MutationTypes.SET_CURRENT_USER)
    ], UpdateDocumentsMentor.prototype, "setCurrentUser", void 0);
    UpdateDocumentsMentor = __decorate([
        Component({
            components: {
                MentorSearchResult: MentorSearchResult,
                Navbar: Navbar,
                CarclewFooter: CarclewFooter,
                Multiselect: Multiselect,
                'vue-dropzone': vue2Dropzone
            },
            metaInfo: function () {
                return {
                    title: 'Carclew | Update Documents'
                };
            }
        })
    ], UpdateDocumentsMentor);
    return UpdateDocumentsMentor;
}(Vue));
export default UpdateDocumentsMentor;
